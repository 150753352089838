import {
  Box,
  Button,
  Text,
  TextInput,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { CloseIcon, PlusSignIcon, SearchIcon } from '../../../../assets/svg';
import { useCallback, useEffect, useRef } from 'react';
import {
  useCreditCardsFilters,
  useCreditCardsModalFilters,
} from './use-credit-cards-filters';
import IssueCard from '../../issue-card';
import useModal from '@common/modal/modal-hook';
import { CardStatusFilter } from './filters/card-status.filter';
import { CreditCardsCardholderFilter } from './filters/cardholder.filter';
import { CreditCardsDateRangeFilter } from './filters/credit-cards-date-range.filter';
import { CardTypeFilter } from './filters/card-type.filter';
import { CardNumberFilter } from './filters/card-number.filter';
import { CardNameFilter } from './filters/card-name.filter';
import { CardSpentAmountsFilter } from './filters/card-spent-amounts.filter';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from '../../../../states/application/product-onboarding';
import { FilterModal } from '@common/filter/filter-modal';
import { IoFunnelOutline } from 'react-icons/io5';
import { isTruthyString } from '@utilities/validators/validate-string';
import { CreditCard } from '@services/flexbase/card.model';

type Props = { onCardCreated: () => void; cardsData: CreditCard[] };

const hasUnitChargeCards = (cardsData: CreditCard[]) => {
  return cardsData.some(
    ({ cardSubtype }) =>
      cardSubtype === 'businessCreditCard' ||
      cardSubtype === 'businessVirtualCreditCard',
  );
};

export function CreditCardsTableHeader({ onCardCreated, cardsData }: Props) {
  const isAdmin = useRecoilValue(IsAdmin);
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { openFullModal, closeAllModals } = useModal();
  const modal = useModal();
  const isDisabledNewCreditCardButton = hasUnitChargeCards(cardsData);

  const {
    removeFilter: removeFilterModal,
    removeAllFilters: removeAllFiltersModal,
    applyAllFilters: applyAllModalFilters,
    activeFiltersArray: activeFiltersModal,
  } = useCreditCardsModalFilters();

  const {
    applyAllFilters: applyAllTableFIlters,
    removeAllFilters,
    activeFiltersArray: activeFilterTable,
    getFilterByKey,
    addFilter,
    removeFilter,
  } = useCreditCardsFilters();

  const activeFiltersModalRef = useRef(activeFiltersModal);
  const activeFilterTableRef = useRef(activeFilterTable);

  useEffect(() => {
    activeFiltersModalRef.current = activeFiltersModal;
    activeFilterTableRef.current = activeFilterTable;
  }, [activeFiltersModal, activeFilterTable]);

  const onSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (transaction) => {
        const normalizedFilterText = value.toLowerCase().trim();
        return Object.values(transaction)
          .filter(isTruthyString)
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const onIssueCardClick = () => {
    openFullModal(
      <IssueCard closeModal={closeAllModals} addCompanyCard={onCardCreated} />,
    );
  };

  const filters = [
    {
      key: 'status',
      header: 'Status',
      component: <CardStatusFilter />,
    },
    {
      key: 'type',
      header: 'Type',
      component: <CardTypeFilter />,
    },
    {
      key: 'cardholder',
      header: 'Cardholder',
      component: <CreditCardsCardholderFilter cards={cardsData} />,
    },
    {
      key: 'number',
      header: 'Number',
      component: <CardNumberFilter cards={cardsData} />,
    },
    {
      key: 'name',
      header: 'Card name',
      component: <CardNameFilter cards={cardsData} />,
    },
    {
      key: 'spent',
      header: 'Monthly usage',
      component: <CardSpentAmountsFilter />,
    },
    {
      key: 'date',
      header: 'Last modified',
      component: <CreditCardsDateRangeFilter />,
    },
  ];

  const applyModalFiltersToTable = useCallback(() => {
    const filtersToApply = activeFiltersModalRef.current.reduce<
      Record<string, any>
    >((obj, filter) => {
      obj[filter.key] = filter;
      return obj;
    }, {});

    applyAllTableFIlters(filtersToApply);
  }, [activeFiltersModalRef.current]);

  const applyTableFiltersToModal = useCallback(() => {
    const filtersToApply = activeFilterTableRef.current.reduce<
      Record<string, any>
    >((obj, filter) => {
      obj[filter.key] = filter;
      return obj;
    }, {});
    applyAllModalFilters(filtersToApply);
  }, [activeFilterTableRef.current]);

  const filterChips = activeFilterTable.filter((f) => f.showChip);

  const openModal = () => {
    screen.width <= 767
      ? modal.openFullModal(
          <FilterModal
            filters={filters}
            applyFilterToTable={applyModalFiltersToTable}
            applyFilterToModal={applyTableFiltersToModal}
          />,
        )
      : modal.openRightModal(
          <FilterModal
            filters={filters}
            applyFilterToTable={applyModalFiltersToTable}
            applyFilterToModal={applyTableFiltersToModal}
          />,
        );
  };

  return (
    <>
      <Box
        sx={() => ({
          display: 'flex',
          gap: theme.spacing.md,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
        mb="md"
      >
        <Box
          sx={() => ({
            display: 'flex',
            gap: theme.spacing.md,
            [theme.fn.smallerThan('sm')]: {
              flexDirection: 'column',
              width: '100%',
            },
          })}
        >
          <TextInput
            width={368}
            placeholder="Search cards"
            icon={<SearchIcon width={20} height={20} />}
            value={getFilterByKey('search')?.filterValue || ''}
            onChange={(e) => onSearchChange(e.target.value)}
          />
          <Button
            variant="outline"
            rightIcon={<IoFunnelOutline />}
            onClick={() => openModal()}
          >
            Filters
          </Button>
          {isAdmin && (
            <Button
              variant="outline"
              leftIcon={<PlusSignIcon />}
              onClick={onIssueCardClick}
              disabled={isDisabledNewCreditCardButton}
            >
              New credit card
            </Button>
          )}
        </Box>
      </Box>
      {filterChips.length > 0 && (
        <Box
          sx={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}
          mih={30}
          mb="md"
        >
          {filterChips.map((p) => (
            <Box
              sx={() => ({
                height: 30,
                borderRadius: theme.radius.xs,
                border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
                backgroundColor: theme.fn.themeColor('neutral', 2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: `0px ${theme.spacing.md}`,
                gap: 4,
                [theme.fn.smallerThan('md')]: {
                  height: 'auto',
                  padding: `0px ${theme.spacing.xl}`,
                },
              })}
              onClick={() => {
                removeFilter(p.key);
                removeFilterModal(p.key);
              }}
              key={p.key}
            >
              <Text color="black" fz={14} fw={500}>
                {p.label}
              </Text>
              <CloseIcon color="black" style={{ marginLeft: 'auto' }} />
            </Box>
          ))}
          <Text
            className={classes.clearFilters}
            onClick={() => {
              removeAllFilters();
              removeAllFiltersModal();
            }}
          >
            Clear filters
          </Text>
        </Box>
      )}
    </>
  );
}

const useStyles = createStyles((theme) => ({
  clearFilters: {
    cursor: 'pointer',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
}));
