import { Box, Button, Flex, Group, Space, Text, rem } from '@mantine/core';
import { MouseEventHandler, PropsWithChildren } from 'react';
import { WizardLabelsConfig } from '../wizard.types';

type WizardStepProps = PropsWithChildren<{
  title?: string;
  labels: WizardLabelsConfig;
  hideBack?: boolean;
  hideNext?: boolean;
  backLoading?: boolean;
  nextLoading?: boolean;
  onBack?: MouseEventHandler<HTMLButtonElement>;
  onNext?: MouseEventHandler<HTMLButtonElement>;
}>;

export const WizardStep = ({
  children,
  title,
  labels,
  hideBack,
  hideNext,
  backLoading,
  nextLoading,
  onBack,
  onNext,
}: WizardStepProps) => {
  return (
    <Flex justify="center">
      <Box w="29rem">
        {title ? (
          <Text fz={32} lh={rem(48)} mb={40}>
            {title}
          </Text>
        ) : null}

        {children}

        <Group position="apart" mt={40}>
          {!hideBack ? (
            <Button variant="outline" onClick={onBack} loading={backLoading}>
              {labels.back || 'Back'}
            </Button>
          ) : (
            <Space />
          )}

          {!hideNext ? (
            <Button
              onClick={onNext}
              loading={nextLoading}
              disabled={nextLoading}
            >
              {labels.next || 'Continue'}
            </Button>
          ) : null}
        </Group>
      </Box>
    </Flex>
  );
};
