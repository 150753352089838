import { showNotification } from '@mantine/notifications';
import {
  useCreateRecipient,
  useUpdateRecipient,
} from '@queries/use-recipients';
import {
  CounterpartyRequest,
  CreateBeneficiary,
} from '@services/flexbase/banking.model';
import { UseMutateFunction } from '@tanstack/react-query';
import { useQueryParams } from '@utilities/url/query-param';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'underscore';
import { recipientDetailsFormInitialValues } from './add-recipient';
import {
  RecipientDetailsStepFormValues,
  isIndividual,
} from './steps/recipient-details/recipient-details-step';

const shouldCreateRecipient = (
  values: RecipientDetailsStepFormValues,
  recipientIdQueryParam: string | null,
): values is RecipientDetailsStepFormValues => {
  return (
    !isEqual(values, recipientDetailsFormInitialValues) &&
    !recipientIdQueryParam
  );
};

const shouldUpdateRecipientWithDob = (
  isIndividualEntity: boolean,
  recipientId: string | null,
  dob?: string,
) => {
  return isIndividualEntity && !!recipientId && !!dob;
};

const buildNewParams = (
  params: CreateBeneficiary | CounterpartyRequest,
  recipientId: string | null,
) => {
  const isCounterpartyRequest = 'type' in params;

  return isCounterpartyRequest
    ? {
        ...params,
        counterparty: {
          ...params.counterparty,
          recipientId: recipientId,
        },
      }
    : {
        ...params,
        recipientId,
      };
};

export const useAddRecipientSubmit = (
  recipientDetailsFormValues: RecipientDetailsStepFormValues,
  givenMutation: UseMutateFunction<any, Error, any, unknown>,
) => {
  const { mutate: createRecipient, isPending: isPendingRecipientCreation } =
    useCreateRecipient();
  const { mutate: updateRecipient, isPending: isPendingRecipientUpdate } =
    useUpdateRecipient();
  const query = useQueryParams();
  const navigate = useNavigate();

  const navigateUponSuccess = () => {
    navigate(-1);
    showNotification({
      message: `Recipient successfully created`,
      color: 'flexbase-teal',
    });
  };

  const recipientIdQueryParam = query.get('recipientId');
  const isIndividualEntity = isIndividual(recipientDetailsFormValues.type);

  const submitRecipient = (
    params: CreateBeneficiary | CounterpartyRequest,
    dob?: string,
  ) => {
    /*
    We must include `dob` field for new + existing recipients when also
    creating an 'individual'-type beneficiary, as it's required for int'l payments,
    but optional for domestic payments.
    */
    if (dob) {
      recipientDetailsFormValues.dob = dob;
    }

    if (
      shouldUpdateRecipientWithDob(
        isIndividualEntity,
        recipientIdQueryParam,
        dob,
      )
    ) {
      updateRecipient(
        {
          id: recipientIdQueryParam!,
          params: { dob },
        },
        {
          onSuccess: (data) => {
            const newParams = buildNewParams(params, data.recipient.id);

            givenMutation(
              {
                ...newParams,
              },
              {
                onSuccess: () => {
                  navigateUponSuccess();
                },
              },
            );
          },
        },
      );
    } else if (
      shouldCreateRecipient(recipientDetailsFormValues, recipientIdQueryParam)
    ) {
      createRecipient(recipientDetailsFormValues, {
        onSuccess: (data) => {
          const newRecipientId = data.recipient.id;
          const newParams = buildNewParams(params, newRecipientId);

          givenMutation(
            {
              ...newParams,
            },
            {
              onSuccess: () => {
                navigateUponSuccess();
              },
            },
          );
        },
      });
    } else {
      const newParams = buildNewParams(params, recipientIdQueryParam);

      givenMutation(
        {
          ...newParams,
        },
        {
          onSuccess: () => {
            navigateUponSuccess();
          },
        },
      );
    }
  };

  return {
    submitRecipient,
    isPending: isPendingRecipientCreation || isPendingRecipientUpdate,
  };
};
