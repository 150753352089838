import { useQuery } from '@tanstack/react-query';
import { flexbaseBankingClient } from 'services/flexbase-client';

const QUERY_KEY = 'routingNumber';

export const useRoutingNumberSupport = (
  routingNumber: string,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, routingNumber],
    queryFn: async () => {
      return await flexbaseBankingClient.checkRoutingNumberUnit(routingNumber);
    },
    retry: 0,
    enabled,
  });
};
