import { useState } from 'react';
import ReviewAndPay from './review-and-pay';
import PaymentSuccess from './payment-success';
import PaymentError from './payment-error';
import {
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';
import { RightContentModal } from '@common/modal/right-content.modal';

type Props = { closeModal: () => void };
const CardRepaymentModal = ({ closeModal }: Props) => {
  const [step, setStep] = useState<'review' | 'success' | 'error'>('review');
  const [amountPaid, setAmountPaid] = useState(0);
  const [paymentId, setPaymentId] = useState('');
  const [paymentAccount, setPaymentAccount] = useState<
    PlaidAccount | DepositAccount | undefined
  >();
  const [paymentStatus, setPaymentStatus] = useState('');

  const getContent = () => {
    switch (step) {
      case 'review':
        return (
          <ReviewAndPay
            closeModal={closeModal}
            onSuccess={(id, paymentAmount, paymentAcct, status) => {
              setAmountPaid(paymentAmount);
              setPaymentId(id);
              setPaymentAccount(paymentAcct);
              setStep('success');
              setPaymentStatus(status);
            }}
            onError={() => setStep('error')}
          />
        );
      case 'success':
        return (
          <PaymentSuccess
            paymentAccount={paymentAccount}
            paymentAmount={amountPaid}
            paymentId={paymentId}
            onCloseClick={closeModal}
            setStep={setStep}
            paymentStatus={paymentStatus}
          />
        );
      case 'error':
        return (
          <PaymentError
            onClose={closeModal}
            onTryAgain={() => {
              setPaymentId('');
              setAmountPaid(0);
              setPaymentAccount(undefined);
              setStep('review');
            }}
          />
        );
    }
  };

  return (
    <RightContentModal>
      <RightContentModal.Header titleSize={30} title="Make a credit payment" />
      <RightContentModal.Body>{getContent()}</RightContentModal.Body>
    </RightContentModal>
  );
};

export default CardRepaymentModal;
