import {
  BankingIcon,
  BankingIconActive,
  CreditIcon,
  CreditIconActive,
  DocumentsIcon,
  DocumentsIconActive,
  InsuranceIcon,
  InsuranceIconActive,
  PaymentIcon,
  PaymentIconActive,
  SettingsIconNew,
  SettingsIconNewActive,
  SpendPlanIcon,
  TeamsIcon,
  TeamsIconActive,
  TreasuryIcon,
  TreasuryIconActive,
} from 'assets/svg';
import { ReactElement } from 'react';
import { IconType } from 'react-icons';
import { UserRole } from 'states/user/user-info';
import {
  useBillPayFeatureFlag,
  useBillPayInternalFeatureFlag,
  useSpendPlansFeatureFlag,
} from '@utilities/feature-flags';
import { OptedProduct } from '../../states/application/product-onboarding';

export type SubMenuOption = {
  label: string;
  location: string;
  disabled?: boolean;
};

export type MenuOption = {
  label: string;
  location: string;
  icon: ReactElement<IconType>;
  disabled?: boolean;
  subRoutes?: SubMenuOption[];
  onClick?: () => void;
  unclickable?: boolean;
  devOnly?: boolean;
  adminOnly?: boolean;
  rolesAllowed?: UserRole[];
  activeIcon: ReactElement<IconType>;
  product: OptedProduct | null;
};

const getNavbarOptions = (
  featureFlags: Record<string, boolean>,
): MenuOption[] => [
  {
    label: 'Treasury Management',
    location: '/accounts',
    icon: <TreasuryIcon style={{ marginLeft: '2px', marginBottom: '3px' }} />,
    activeIcon: (
      <TreasuryIconActive style={{ marginLeft: '2px', marginBottom: '3px' }} />
    ),
    product: 'TREASURY',
  },
  {
    label: 'Credit',
    location: '/cards',
    icon: <CreditIcon />,
    activeIcon: <CreditIconActive />,
    product: 'CREDIT',
  },
  {
    label: 'Banking',
    location: '/banking',
    icon: <BankingIcon />,
    rolesAllowed: ['ADMIN', 'ACCOUNTANT'],
    activeIcon: <BankingIconActive />,
    product: 'BANKING',
  },
  {
    label: 'Spend plans',
    location: '/spend-plans',
    icon: <SpendPlanIcon />,
    activeIcon: <SpendPlanIcon />,
    product: null,
    disabled: !featureFlags.spendPlansEnabled,
  },
  {
    label: 'Payments & Bill Pay',
    location: '',
    icon: <PaymentIcon />,
    rolesAllowed: ['ADMIN', 'ACCOUNTANT', 'COMPTROLLER'],
    activeIcon: <PaymentIconActive />,
    product: 'BANKING',
    subRoutes: [
      {
        label: 'Bill pay',
        location: '/bill-pay',
        disabled: !featureFlags.billingEnabled,
      },
      {
        label: 'Payments',
        location: '/payments',
      },
      {
        label: 'Bill pay internal',
        location: '/billpay',
        disabled: !featureFlags.billPayInternalEnabled,
      },
    ],
  },
  {
    label: 'Insurance',
    location: '/insurance',
    icon: <InsuranceIcon />,
    activeIcon: <InsuranceIconActive />,
    disabled: true,
    unclickable: true,
    product: null, // We don't yet have a specific insurance product.
  },
];

/**
 * Modify this to add feature-flagged menu options
 */
export const useTopNavbarOptions = () => {
  const billingEnabled = useBillPayFeatureFlag();
  const spendPlansEnabled = useSpendPlansFeatureFlag();
  const billPayInternalEnabled = useBillPayInternalFeatureFlag();

  const NAVBAR_OPTIONS = getNavbarOptions({
    billingEnabled,
    spendPlansEnabled,
    billPayInternalEnabled,
  });

  return NAVBAR_OPTIONS;
};

export const BOTTOM_NAVBAR_OPTIONS: MenuOption[] = [
  {
    label: 'Documents',
    location: '/documents',
    icon: <DocumentsIcon />,
    activeIcon: <DocumentsIconActive />,
    product: null,
    adminOnly: true,
    devOnly: true,
  },
  {
    label: 'Team',
    location: '/team',
    icon: <TeamsIcon />,
    activeIcon: <TeamsIconActive />,
    adminOnly: true,
    product: null,
  },
  {
    label: 'Settings',
    location: '/settings',
    icon: <SettingsIconNew />,
    activeIcon: <SettingsIconNewActive />,
    product: null,
  },
];
