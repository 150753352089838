import { showNotification } from '@mantine/notifications';
import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  BaseRecipient,
  CreateRecipientParams,
} from 'states/recipient/recipient';

export const RECIPIENTS = 'recipients';

export const useGetAllRecipients = () => {
  return useQuery({
    queryKey: [RECIPIENTS],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getAllRecipients();
    },
    meta: {
      errorMessage: 'Unable to retrieve info for recipients at this time',
    },
  });
};

export const RECIPIENT = 'recipient';

export const useGetRecipient = (recipientId: string) => {
  return useQuery({
    queryKey: [RECIPIENT, recipientId],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getRecipient(recipientId);
    },
    enabled: !!recipientId,
    meta: {
      errorMessage: 'Unable to retrieve recipient info at this time',
    },
  });
};

type UpdateMutationParams = {
  id: string;
  params: Partial<BaseRecipient>;
};

export const useUpdateRecipient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, params }: UpdateMutationParams) => {
      return await flexbaseOnboardingClient.updateRecipient(id, params);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [RECIPIENT, data.recipient.id],
      });
    },
    onError: () => {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'We were unable to update the recipient info',
      });
    },
  });
};

export const useDeleteRecipient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      return await flexbaseOnboardingClient.deleteRecipient(id);
    },
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({
        queryKey: [RECIPIENT, id],
      });
    },
  });
};

export const useCreateRecipient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: CreateRecipientParams) => {
      return await flexbaseOnboardingClient.createRecipient(params);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [RECIPIENTS],
      });
    },
    onError: (error) => {
      console.error('Error creating recipient', error);
      showNotification({
        color: 'red',
        title: 'Error',
        message: `We were unable to create a new recipient.`,
      });
    },
  });
};
