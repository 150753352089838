import { Outlet } from 'react-router-dom';
import { NavTabContainer } from '@common/tabs/nav-tab-container';
import BillpaySummary from './billpay-summary';

const BILLPAY_TABS = [
  { label: 'Bills', route: '/billpay/bills' },
  { label: 'Action required', route: '/billpay/action-required' },
  { label: 'Payments', route: '/billpay/payments' },
  {
    label: 'Vendors & recipients',
    route: '/billpay/vendors',
  },
];

const BillingPage = () => {
  return (
    <>
      <BillpaySummary />
      <NavTabContainer tabs={BILLPAY_TABS}>
        <Outlet />
      </NavTabContainer>
    </>
  );
};

export default BillingPage;
