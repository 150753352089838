import AuthLoader from '@common/login/auth-loader';
import { useRouteSectionContext } from '@common/routes/route-context';
import { useCreateInternationalPaymentsApplication } from '@queries/use-international-payments';
import { InternationalPaymentsAppStatus } from '@services/flexbase/banking.model';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAdmin,
} from 'states/application/product-onboarding';
import OnboardingEndScreen from '../components/onboarding-end-screen';
import { Text, createStyles, rem } from '@mantine/core';
import { ProductStatus } from 'states/application/product-onboarding.models';

type ContentProps = {
  isAdmin: boolean;
  isError: boolean;
  productStatus: ProductStatus;
  status?: InternationalPaymentsAppStatus;
};

const getContent = ({
  isAdmin,
  status,
  isError,
  productStatus,
}: ContentProps) => {
  const isFullyApproved =
    productStatus.appStatus === 'approved' &&
    productStatus.status === 'approved';

  if (!isAdmin && !isFullyApproved) {
    return {
      title: 'International payments is not available...yet.',
      description:
        'Please reach out to your company owner to fully get access to international payments.',
    };
  }

  if (!isAdmin && isFullyApproved) {
    return {
      title: 'Application approved',
      description: 'You now have access to international wire payments!',
    };
  }

  if (productStatus.invalidOfficers?.length) {
    return {
      title: 'Almost done!',
      description:
        'All officers must accept their invites and provide identification data. Once this is done your application will be processed.',
    };
  }

  if (isError) {
    return {
      title: 'Something went wrong',
      description: 'Please refresh and try again.',
    };
  }

  switch (status) {
    case 'AwaitingDocuments':
      return {
        title: 'Application submitted',
        description:
          'We need a few more documents before we process your application for international wire payments.',
      };
    case 'Pending':
      return {
        title: 'Application submitted',
        description:
          'Your application to access international wire payments is now pending review.',
      };
    case 'Approved':
      return {
        title: 'Application approved',
        description: 'You now have access to international wire payments!',
      };
    case 'Denied':
      return {
        title: 'Application denied',
        description: 'Please reach out to support for more information.',
      };
    case 'Canceled':
      return {
        title: 'Application canceled',
        description: 'Please reach out to support for more information.',
      };
    default:
      return {
        title: 'Application submitted',
        description:
          'We are reviewing your application for international wire payments and will be in touch.',
      };
  }
};

export const InternationPaymentApplicationSuccessPage = () => {
  const { setSectionAndNavigate } = useRouteSectionContext();
  const { productStatus } = useRecoilValue(ApplicationState);
  const {
    data: applicationData,
    mutate: createApplication,
    isPending,
    isError,
  } = useCreateInternationalPaymentsApplication();
  const { classes } = useStyles();
  const isAdmin = useRecoilValue(IsAdmin);

  useEffect(() => {
    if (isAdmin) {
      createApplication();
    }
  }, [isAdmin]);

  const { title, description } = getContent({
    isAdmin,
    isError,
    status: applicationData?.status,
    productStatus: productStatus.internationalPayments,
  });

  const handleClick = () => {
    setSectionAndNavigate('main', '/banking/accounts');
  };

  if (isPending) {
    return (
      <AuthLoader
        classNames={{ loadingContainer: classes.loader }}
        label={
          <Text className={classes.loaderLabel}>Submitting Application</Text>
        }
      />
    );
  }

  return (
    <OnboardingEndScreen
      title={title}
      description={description}
      graphic={'/images/CardBack2x.png'}
      primaryButtonText={'Back to app'}
      onPrimaryButtonClick={handleClick}
    />
  );
};

const useStyles = createStyles((theme) => ({
  loader: {
    backgroundColor: 'transparent',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
  loaderLabel: {
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    fontSize: rem(32),
  },
}));
