import { useState } from 'react';
import { Box, rem, Text, useMantineTheme } from '@mantine/core';
import { CardIcon } from 'assets/svg';
import AccountBox from '@common/account-box/account-box';
import { LineOfCredit } from '@services/flexbase/credit.model';
import { formatCurrency } from '@utilities/formatters/format-currency';

export type AccountLOC = {
  id: string;
  name: string;
  balance: number;
  isFrozen: boolean;
  minimumDue: number;
  currentBalance: number;
  maxPaymentAmount: number;
  minPaymentAmount: number;
  lineOfCredit: LineOfCredit;
};

type LOCSelect = {
  accounts: AccountLOC[];
  selectedAccount: AccountLOC;
  onSelectedAccount?: (acc: AccountLOC) => void;
};

const LineOfCreditSelect = ({
  accounts = [],
  selectedAccount,
  onSelectedAccount,
}: LOCSelect) => {
  const theme = useMantineTheme();
  const [open, setOpen] = useState(false);
  const hasMorethanOneAccount = accounts.length > 1;
  const accountsInList = accounts.filter(
    (acc) => acc.id !== selectedAccount.id,
  );

  const handleDropdownClick = () => {
    if (!hasMorethanOneAccount) {
      return;
    }
    setOpen(!open);
  };

  const onAccountChange = (acc: AccountLOC) => {
    if (onSelectedAccount) {
      onSelectedAccount(acc);
      setOpen(false);
    }
  };

  return (
    <Box pos="relative" mb="xxxl">
      <Text size="lg">Select a line of credit to pay</Text>
      <AccountBox
        showBorder
        rotateArrow={open}
        onClick={handleDropdownClick}
        headerText={selectedAccount.name}
        showArrow={hasMorethanOneAccount}
        subheaderText={`${formatCurrency(selectedAccount.balance)} available`}
        icon={
          <CardIcon color={theme.fn.themeColor('primarySecondarySuccess', 8)} />
        }
      />
      {open && (
        <Box
          p="xs"
          mt="xs"
          w="100%"
          bg="neutral.0"
          sx={{
            zIndex: 1,
            maxHeight: rem(250),
            position: 'absolute',
            boxShadow: theme.shadows.sm,
            borderRadius: theme.defaultRadius,
          }}
        >
          {accountsInList.map((acc) => (
            <AccountBox
              isListItem
              key={acc.id}
              headerText={acc.name}
              onClick={() => onAccountChange(acc)}
              subheaderText={`${formatCurrency(acc.balance)} available`}
              icon={
                <CardIcon
                  color={theme.fn.themeColor('primarySecondarySuccess', 8)}
                />
              }
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default LineOfCreditSelect;
