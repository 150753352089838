import { Button, Center, Loader, Text } from '@mantine/core';
import { useGetBeneficiaryRequirements } from '@queries/use-beneficiaries';
import type { BeneficiaryRequirements } from '@services/flexbase/banking.model';
import {
  AddRecipientWizard,
  useAddRecipientWizard,
} from '../../add-recipient-wizard';
import { isIndividual } from '../recipient-details/recipient-details-step';
import BeneficiaryRequirementsForm from './forms/intl/beneficiary-requirements-form';
import { isInternationalWireUSD } from 'areas/payments/components/send-payment/international-payments/util';
import { submitStepLabels } from '../util';

const removeDuplicateFields = (
  isIndividualEntity: boolean,
  fields?: BeneficiaryRequirements,
) => {
  if (!fields) return {};

  for (const field in fields) {
    if (
      field === 'country' ||
      (isIndividualEntity && (field === 'firstName' || field === 'lastName')) ||
      (!isIndividualEntity && field === 'companyName')
    ) {
      delete fields[field];
    }
  }

  return fields;
};

const BeneficiaryRequirementsStep = () => {
  const { state } = useAddRecipientWizard();
  const { beneficiaryRequirementsFormValues, recipientDetailsFormValues } =
    state;
  const currency = isInternationalWireUSD(state.paymentMethod)
    ? 'USD'
    : beneficiaryRequirementsFormValues.currency;

  const isIndividualBeneficiary = isIndividual(recipientDetailsFormValues.type);
  const requiresDob =
    isIndividualBeneficiary && !recipientDetailsFormValues.dob?.length;

  const { data, isPending, isError, error, refetch } =
    useGetBeneficiaryRequirements({
      currency,
      countryOfRecipientsBank:
        beneficiaryRequirementsFormValues.countryOfRecipientsBank,
      countryOfRecipient: beneficiaryRequirementsFormValues.countryOfRecipient,
      entityType: recipientDetailsFormValues.type,
    });

  if (isPending) {
    return (
      <AddRecipientWizard.Step labels={submitStepLabels}>
        <Center my="1.5rem">
          <Loader variant="dots" color="primarySecondarySuccess.2" />
        </Center>
      </AddRecipientWizard.Step>
    );
  }

  if (isError) {
    const errorMessage: string = JSON.parse(error.message).error;

    return (
      <AddRecipientWizard.Step>
        <Center my="1.5rem">
          <Text>{errorMessage}</Text>
          {!errorMessage.includes('Failed to generate') && (
            <Button
              variant="subtle"
              compact
              onClick={() => {
                refetch();
              }}
            >
              Retry
            </Button>
          )}
        </Center>
      </AddRecipientWizard.Step>
    );
  }

  if (data.fields) {
    return (
      <BeneficiaryRequirementsForm
        fields={removeDuplicateFields(isIndividualBeneficiary, data.fields)}
        recipientCountryCode={
          beneficiaryRequirementsFormValues.countryOfRecipient!
        }
        requiresDob={requiresDob}
      />
    );
  }

  return false;
};

export default BeneficiaryRequirementsStep;

BeneficiaryRequirementsStep.stepId = 'intl-payment-details-step';
