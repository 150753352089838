import { flexbaseOnboardingClient } from '@services/flexbase-client';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEY = 'billPayBalance';

export const useGetBillingBalance = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await flexbaseOnboardingClient.getBillpayBalances();
    },
    meta: {
      errorMessage: 'Unable to get bill pay balance data',
    },
  });
};
