import { Anchor, Box, Stack, StackProps, Text } from '@mantine/core';
import { useGetTermsOfServiceDocs } from '@queries/use-terms-of-service-docs';

type Props = StackProps;

export const CreditBankingFooter = (props: Props) => {
  const { data: terms } = useGetTermsOfServiceDocs({
    termsType: 'flexbase',
    useAuth: false,
  });

  const termsAndConditions = terms?.find(
    (t) => t.label === 'Terms and Conditions',
  );
  const privacyPolicy = terms?.find((t) => t.label === 'Privacy Policy');

  return (
    <Stack spacing="1.5rem" p="2rem" {...props}>
      <Box
        fz={10.5}
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          gap: '4rem',
          [theme.fn.smallerThan('xs')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: theme.spacing.md,
          },
        })}
      >
        <Anchor
          target="_blank"
          c="neutral.0"
          href={termsAndConditions?.url || 'https://home.flex.one/legal'}
        >
          Terms &amp; Conditions
        </Anchor>
        <Anchor
          target="_blank"
          c="neutral.0"
          href={privacyPolicy?.url || 'https://home.flex.one/legal'}
        >
          Privacy Policy
        </Anchor>
        <Anchor c="neutral.0" href="mailto:support@flex.one">
          support@flex.one
        </Anchor>
        <Anchor c="neutral.0" href="tel:+1(415)840-8721">
          (415) 840-8721 ext. 571
        </Anchor>
      </Box>
      <Stack fz={10} spacing="xxs">
        <Text>
          Flexbase Technologies, Inc. is a financial technology company and is
          not a bank. Banking services provided by Thread Bank, Member FDIC. The
          Flexbase Technologies Inc. Visa® Debit Card (&quot;Flex Debit
          Card&quot; / &quot;Flex Banking&quot;) is issued by Thread Bank
          pursuant to a license from Visa U.S.A. Inc. and may be used anywhere
          Visa debit cards are accepted.
        </Text>
        <Text>
          The Flex Commercial Credit Card (&quot;Flex Credit Card&quot;) is
          issued by Patriot Bank, N.A., pursuant to a license from Mastercard®
          International Incorporated. The Flex Credit Card can be used
          everywhere Mastercard is accepted. Mastercard is a registered
          trademark of Mastercard International. Flex provides the credit for
          the Flex Credit Card. The Flex Credit Card is not available yet in the
          following states: CA, ND, SD, VT, and NV.
        </Text>
        <Text>
          *0% interest applies if the full balance is paid within the 60-day
          interest-free grace period. Interest will begin to accrue if the full
          balance is not paid by the end of the current bi-monthly billing
          period following the 60-day interest-free grace period. See your Flex
          Commercial Cardholder Agreement for details.
        </Text>
        <Text>**See your Flex Cashback Terms and Conditions for details.</Text>
        <Text>
          ***Annual Percentage Yield (APY) of up to 3.34% is accurate as of
          09/20/2024. This is a variable rate based on the effective range of
          the Federal Funds Rate and may change after the account is opened. An
          average daily Account balance that is greater than $1,000,000 for at
          least thirty (30) days is required to be eligible to earn 3.34% APY.
        </Text>
        <Text>
          ****Your deposits qualify for up to $3,000,000 in FDIC insurance
          coverage when placed at program banks in the Thread Bank deposit sweep
          program. Your deposits at each program bank become eligible for FDIC
          insurance up to $250,000, inclusive of any other deposits you may
          already hold at the bank in the same ownership capacity. You can
          access the terms and conditions of the sweep program at{' '}
          <Anchor
            c="neutral.0"
            target="_blank"
            rel="noreferrer"
            href="https://go.thread.bank/sweepdisclosure"
          >
            https://go.thread.bank/sweepdisclosure
          </Anchor>{' '}
          and a list of program banks at{' '}
          <Anchor
            c="neutral.0"
            target="_blank"
            rel="noreferrer"
            href="https://go.thread.bank/programbanks"
          >
            https://go.thread.bank/programbanks
          </Anchor>
          . Please contact{' '}
          <Anchor c="neutral.0" href="mailto:customerservice@thread.bank">
            customerservice@thread.bank
          </Anchor>{' '}
          with questions regarding the sweep program. Pass-through insurance
          coverage is subject to conditions.
        </Text>
        <Text>
          ©2023 Flexbase Technologies, Inc., d/b/a Flex, &quot;Flex&quot; and
          the Flex logo are registered trademarks. Flex products may not be
          available to all customers. See the Flex Terms of Service for details.
          Terms are subject to change.
        </Text>
      </Stack>
    </Stack>
  );
};
