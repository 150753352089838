import { WizardStepConfig } from '@common/wizard/wizard.types';
import { LoadingOverlay } from '@mantine/core';
import { useGetRecipient } from '@queries/use-recipients';
import { useAddRecipientFlowFeatureFlag } from '@utilities/feature-flags';
import { useQueryParams } from '@utilities/url/query-param';
import { getNewInitialInternationalRecipientFormValues } from 'areas/payments/components/send-payment/international-payments/hooks/use-international-recipient-form';
import {
  DomesticAchRecipient,
  DomesticWireRecipient,
  FieldRecord,
  IndividualOrCompany,
} from 'areas/payments/components/send-payment/international-payments/util/types';
import { Navigate, useNavigate } from 'react-router-dom';
import { AddRecipientState, AddRecipientWizard } from './add-recipient-wizard';
import BeneficiaryRequirementsStep from './steps/payment-details/beneficiary-requirements-step';
import { PaymentDetailsStep } from './steps/payment-details/payment-details-step';
import { PaymentTypeStep } from './steps/payment-type/payment-type-step';
import {
  RecipientDetailsStep,
  RecipientDetailsStepFormValues,
} from './steps/recipient-details/recipient-details-step';
import { isEqual } from 'underscore';

export const recipientDetailsFormInitialValues: RecipientDetailsStepFormValues =
  {
    name: '',
    firstName: '',
    lastName: '',
    type: 'company' as IndividualOrCompany,
  };

const domesticAchRecipientFormInitialValues: DomesticAchRecipient = {
  type: 'ach',
  nickName: '',
  routingNumber: '',
  accountNumber: '',
  recipientType: 'individual',
  accountType: '',
};

const domesticWireRecipientFormInitialValues: DomesticWireRecipient = {
  type: 'ach',
  nickName: '',
  routingNumber: '',
  accountNumber: '',
  recipientType: 'individual',
  address: '',
  addressLine2: '',
  city: '',
  state: '',
  postalCode: '',
  country: 'US',
};

export type PaymentMethodStepValue =
  | 'domesticACH'
  | 'domesticWire'
  | 'internationalWireUSD'
  | 'internationalWire';

export const PAYMENT_STEP_VALUES: Record<string, PaymentMethodStepValue> = {
  domesticACH: 'domesticACH',
  domesticWire: 'domesticWire',
  internationalWireUSD: 'internationalWireUSD',
  internationalWire: 'internationalWire',
};

const initialBeneficiaryRequirementsFormValues =
  getNewInitialInternationalRecipientFormValues(
    '',
    'internationalWire',
    'individual',
  );

export const isInitialBeneficiaryRequirementsFormValues = (
  formValues: FieldRecord,
) => isEqual(initialBeneficiaryRequirementsFormValues, formValues);

const useGetSteps = () => {
  const query = useQueryParams();
  const recipientIdQueryParam = query.get('recipientId');
  const { data: recipientData, isLoading } = useGetRecipient(
    recipientIdQueryParam || '',
  );

  const steps: WizardStepConfig<AddRecipientState>[] = [
    {
      id: RecipientDetailsStep.stepId,
      title: 'Enter recipient details',
      element: <RecipientDetailsStep />,
    },
    {
      id: PaymentTypeStep.stepId,
      title: 'Select recipient payment type',
      element: <PaymentTypeStep />,
    },
    {
      id: PaymentDetailsStep.stepId,
      title: 'Enter recipient payment details',
      element: <PaymentDetailsStep />,
    },
    {
      id: BeneficiaryRequirementsStep.stepId,
      title: 'Enter international payment details',
      element: <BeneficiaryRequirementsStep />,
      condition: (state) => state.showIntlPaymentStep,
    },
  ];

  const parsedSteps = !recipientData?.recipient
    ? steps
    : steps.filter((step) => step.id !== RecipientDetailsStep.stepId);

  const initialState = {
    paymentMethod: PAYMENT_STEP_VALUES.domesticACH,
    recipientDetailsFormValues: {
      ...recipientDetailsFormInitialValues,
      name: recipientData?.recipient?.name || '',
      type: (recipientData?.recipient?.type as IndividualOrCompany) || '',
    },
    domesticAchRecipientFormValues: domesticAchRecipientFormInitialValues,
    domesticWireRecipientFormValues: domesticWireRecipientFormInitialValues,
    beneficiaryRequirementsFormValues: initialBeneficiaryRequirementsFormValues,
    showIntlPaymentStep: false,
  };

  return {
    steps: parsedSteps,
    isLoadingRecipientData: isLoading,
    initialState,
  };
};

const AddRecipient = () => {
  const isFeatureFlagEnabled = useAddRecipientFlowFeatureFlag();
  const navigate = useNavigate();
  const { steps, isLoadingRecipientData, initialState } = useGetSteps();

  const handleCancelClick = () => {
    navigate(-1);
  };

  if (!isFeatureFlagEnabled) {
    return <Navigate to="/" />;
  }

  if (isLoadingRecipientData) {
    return <LoadingOverlay visible />;
  }

  return (
    <AddRecipientWizard
      steps={steps}
      initialState={initialState}
      onCancel={handleCancelClick}
      wrapper={({ children }) => children}
    />
  );
};

export default AddRecipient;
