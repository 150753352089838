import { Box, createStyles, Flex, Radio, Text, TextInput } from '@mantine/core';
import { useCreateCounterparty } from '@queries/use-counterparties';
import {
  CounterpartyBase,
  createCounterpartyMutationParams,
} from 'areas/payments/components/send-payment/send-payment';
import { useAddRecipientSubmit } from 'areas/payments/pages/add-recipient/use-add-recipient-submit';
import {
  AddRecipientWizard,
  useAddRecipientWizard,
} from '../../../../add-recipient-wizard';
import { useDomesticAchRecipientForm } from './hooks/use-domestic-ach-recipient-form';
import RoutingNumberInput from './routing-number-input';
import { submitStepLabels } from '../../../util';

type RadioItemProps = {
  value: string;
  label: string;
  onchange: (val: string) => void;
  isChecked: boolean;
};

const RadioItem = ({ value, label, onchange, isChecked }: RadioItemProps) => {
  const { classes } = useStyles();
  return (
    <Box
      onClick={() => onchange(value)}
      mb="1rem"
      className={classes.radioContainer}
    >
      <Radio
        value={value}
        label={<Text fw={isChecked ? 700 : 400}>{label}</Text>}
      />
    </Box>
  );
};

const DomesticAchForm = () => {
  const { classes, theme } = useStyles();
  const { setState, state } = useAddRecipientWizard();

  const { recipientDetailsFormValues, domesticAchRecipientFormValues } = state;

  const domesticAchPaymentForm = useDomesticAchRecipientForm(
    domesticAchRecipientFormValues,
    recipientDetailsFormValues.type,
  );

  const {
    mutate: createCounterparty,
    isPending: isPendingCounterpartyCreation,
  } = useCreateCounterparty();

  const { submitRecipient, isPending: isPendingRecipientCreation } =
    useAddRecipientSubmit(recipientDetailsFormValues, createCounterparty);

  const form = domesticAchPaymentForm;

  const isPending = isPendingRecipientCreation || isPendingCounterpartyCreation;

  const handleAccountTypeOnchange = (val: string) => {
    form.setFieldValue('accountType', val);
  };

  const handleNext = async () => {
    form.validate();
    if (form.isValid()) {
      setState((prev) => ({
        ...prev,
        domesticAchRecipientFormValues: form.values,
      }));

      const params = createCounterpartyMutationParams(
        form.values as CounterpartyBase,
        'ach',
        recipientDetailsFormValues.name,
      );

      submitRecipient(params, recipientDetailsFormValues.dob);
    }
  };

  const radioOptions = ['Checking', 'Savings'];

  return (
    <AddRecipientWizard.Step
      onNext={handleNext}
      nextLoading={isPending}
      labels={submitStepLabels}
    >
      <TextInput
        label={
          <Text>
            Account nickname{' '}
            <Text color={theme.colors.neutral[6]} span>
              (Optional)
            </Text>
          </Text>
        }
        placeholder="Enter nickname (optional)"
        {...form.getInputProps('nickName')}
        data-testid="nickName"
      />
      <Flex justify="space-between">
        <RoutingNumberInput paymentMethod="ach" form={form} />
        <TextInput
          className={classes.inputContainer}
          label="Account number"
          placeholder="Enter account number"
          {...form.getInputProps('accountNumber')}
          data-testid="accountNumber"
        />
      </Flex>

      <Radio.Group
        mt={'md'}
        {...form.getInputProps('accountType')}
        label="Account type"
      >
        {radioOptions.map((option) => (
          <RadioItem
            key={option}
            onchange={handleAccountTypeOnchange}
            value={option}
            label={option}
            isChecked={form.values.accountType === option}
          />
        ))}
      </Radio.Group>
    </AddRecipientWizard.Step>
  );
};

export default DomesticAchForm;

const useStyles = createStyles((theme) => ({
  inputContainer: { marginTop: theme.spacing.md, width: '48%' },
  radioContainer: {
    border: `1px solid ${theme.fn.themeColor('primarySecondarySuccess', 2)}`,
    padding: '1.25rem',
    borderRadius: '0.5rem',
    background: theme.colors.contentBackground[2],
  },
}));
