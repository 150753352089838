type RegistrationCopyKey = 'banking' | 'credit';

// This is going to have to be changed as soon as they want different text styling on parts of the text (like a bold "0% for 60 days"). A problem for Future Me
export const REGISTRATION_COPY: Record<
  RegistrationCopyKey,
  { title: string; subtitle: string }
> = {
  credit: {
    title: 'Apply for a Flex Credit Card',
    subtitle: `0% interest for 60 days on every single purchase* and unlimited no-fee employee cards. Plus Flex banking with no monthly fees, free domestic ACH & wires, and up to 3.34% APY.***`,
  },
  banking: {
    title: 'Flex Business Banking',
    subtitle: `No monthly fees. No domestic ACH & wire fees. Up to 3.34% APY.*** $3M FDIC insurance on deposits with Thread Bank.****`,
  },
};
