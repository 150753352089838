import { Box, Flex, Paper, Text, useMantineTheme } from '@mantine/core';
import { formatCurrency } from '@utilities/formatters/format-currency';

type BillingCardProps = {
  title: string;
  balance: number;
  error: boolean;
  billsCount: number;
};

const BillpayCard = ({
  title,
  balance,
  billsCount,
  error,
}: BillingCardProps) => {
  const theme = useMantineTheme();
  const placeholderLineStyle = {
    height: '0.3rem',
    width: '1rem',
    backgroundColor: theme.colors.neutral[9],
    marginBottom: '0.5rem',
    marginLeft: '0.1rem',
  };

  return (
    <Paper
      h={error ? '9.375rem' : 'auto'}
      shadow="xs"
      p="1.5rem"
      radius={0}
      bg={theme.colors.neutral[3]}
    >
      <>
        <Text size="sm" weight={500}>
          {title}
        </Text>
        {error ? (
          <Flex mt="2rem">
            <Box style={placeholderLineStyle}></Box>
            <Box style={placeholderLineStyle}></Box>
          </Flex>
        ) : (
          <>
            <Text size="3rem">{formatCurrency(balance)}</Text>
            <Text size="sm" color={theme.colors.neutral[7]}>
              {billsCount} bills
            </Text>
          </>
        )}
      </>
    </Paper>
  );
};

export default BillpayCard;
