import { Button, Group, TextInput, useMantineTheme } from '@mantine/core';
import { PlusSignIcon, SearchIcon } from 'assets/svg';
import { useMediaQuery } from '@mantine/hooks';
import { useBillpayVendorsFilters } from './use-billpay-vendor-filters';
import { isTruthyString } from '@utilities/validators/validate-string';
import { useNavigate } from 'react-router-dom';

const VendorsHeader = () => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const navigate = useNavigate();
  const { removeFilter, getFilterByKey, addFilter } =
    useBillpayVendorsFilters();

  const handleAddRecipient = () => {
    navigate('/recipients/new');
  };

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (vendor) => {
        const normalizedFilterText = value.toLowerCase().trim();

        const strValues = Object.values(vendor).filter(isTruthyString);

        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  return (
    <Group position="apart" mb={theme.spacing.md}>
      <TextInput
        w={useMobileView ? '100%' : 368}
        placeholder="Search vendors"
        icon={<SearchIcon width={20} height={20} />}
        value={getFilterByKey('search')?.filterValue || ''}
        onChange={(e) => handleSearchChange(e.target.value)}
      />
      <Group>
        <Button
          bg={'primarySecondarySuccess.6'}
          leftIcon={<PlusSignIcon width={12} height={12} />}
          onClick={handleAddRecipient}
        >
          Add Vendor
        </Button>
      </Group>
    </Group>
  );
};

export default VendorsHeader;
