import {
  Box,
  Flex,
  Group,
  Radio,
  Select,
  TextInput,
  createStyles,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import GooglePlacesSuggest from 'components/input/google-places-suggest-input';
import { forwardRef, useImperativeHandle } from 'react';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { US_STATES } from 'states/business/constants';
import { formatZip } from 'utilities/formatters/format-address';
import { validateRequired } from 'utilities/validators/validate-required';
import ValidateRoutingNumber from 'utilities/validators/validate-routing-number';
import { PayMethod, Recipient } from './payment.states';
import { validateBirthDateInSpecifiedFormat } from '@utilities/validators/validate-dates';
import { IndividualOrCompany } from './international-payments/util/types';
import FlexPatternFormat from '../common/flex-pattern-format';
import { Recipient as NewRecipientType } from 'states/recipient/recipient';

export type PaymentRecipientFormRef = {
  submitForm: () => Promise<Recipient | null>;
};

type Props = {
  method: PayMethod;
  isNewRecipient: boolean;
  recipientName: string;
  selectedRecipient?: NewRecipientType;
};

const PaymentRecipientForm = forwardRef<PaymentRecipientFormRef, Props>(
  (_props, ref) => {
    const { classes } = useStyles();
    const { method, isNewRecipient, selectedRecipient, recipientName } = _props;
    // We dont know the first and last name of the recipient, so we'll guesstimate it from the name and push the onus on the user to fill it in.
    const [guesstimatedFirstName, guesstimatedLastName] = isNewRecipient
      ? recipientName.split(' ')
      : '';

    // one form representing both types of recipients.
    // ach recipient counterparty does not need address.
    // wire recipient counterparty does.
    const form = useForm<Recipient>({
      initialValues: {
        routingNumber: '',
        accountNumber: '',
        accountType: '',
        address: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        name: '',
        recipientType: selectedRecipient?.type ?? 'individual',
        type: method as PayMethod,
        nickName: '',
        firstName: guesstimatedFirstName,
        lastName: guesstimatedLastName,
        dob: selectedRecipient?.dob ?? undefined,
      },
      validate: {
        dob: (value, formValues) => {
          if (formValues.recipientType === 'individual' && value) {
            return validateBirthDateInSpecifiedFormat('yyyy-MM-dd', value)
              ? null
              : 'Please input a valid date of birth.';
          }
        },
        routingNumber: (value) =>
          ValidateRoutingNumber(value)
            ? null
            : 'Routing number is invalid. Must be 9 digits and match an existing bank routing number.',
        accountNumber: (value) =>
          !!value && value.length <= 17
            ? null
            : 'A valid account number is required.',
        accountType: (value) => {
          // only required for ach
          if (method === 'ach') {
            return validateRequired(value)
              ? null
              : 'Please select an account type.';
          }
        },
        address: (value) => {
          if (method === 'wire') {
            return validateRequired(value) ? null : 'Please select an address.';
          }
        },
        city: (value) => {
          if (method === 'wire') {
            return validateRequired(value)
              ? null
              : 'Please add the city for the address.';
          }
        },
        state: (value) => {
          if (method === 'wire') {
            return validateRequired(value)
              ? null
              : 'Please select the state for the address.';
          }
        },
        postalCode: (value) => {
          if (method === 'wire') {
            return validateRequired(value)
              ? null
              : 'Please input a 5 digit postal code.';
          }
        },
        country: (value) => {
          if (method === 'wire') {
            return validateRequired(value)
              ? null
              : 'Please input a country code.';
          }
        },
        firstName: (value) => {
          if (isNewRecipient && form.values.recipientType === 'individual') {
            return validateRequired(value) ? null : 'This field is required.';
          }
        },
        lastName: (value) => {
          if (isNewRecipient && form.values.recipientType === 'individual') {
            return validateRequired(value) ? null : 'This field is required.';
          }
        },
      },
    });

    const setZip = (zip: string) => {
      form.setFieldValue('postalCode', formatZip(zip));
    };

    const setCountry = (country: string) => {
      form.setFieldValue('country', country);
    };

    const selectAddress = (item: {
      address: string;
      country: string;
      state: string;
      city: string;
      postalCode: string;
    }) => {
      form.setFieldValue('address', item.address.trim());
      form.setFieldValue('city', item.city);
      form.setFieldValue('state', item.state);
      form.setFieldValue('postalCode', item.postalCode);
      form.setFieldValue('country', item.country);
    };

    const setFormFieldNumber = (value: string, formField: keyof Recipient) => {
      if (value.length === 0) {
        form.setFieldValue(formField, '');
        return;
      }

      const regex = /^\d+$/;
      const matches = regex.test(value);

      if (matches) {
        form.setFieldValue(formField, value);
      }
    };

    const submit = async (): Promise<Recipient | null> => {
      const validationResult = form.validate();

      if (validationResult.hasErrors) {
        return null;
      } else {
        // Perform extra validation on the routing number
        try {
          const valRouting = await flexbaseBankingClient.checkRoutingNumberUnit(
            form.values.routingNumber,
          );
          if (!valRouting.success || !valRouting.institution) {
            form.setFieldError(
              'routingNumber',
              'Routing number is not recognized',
            );
            return null;
          }
          switch (form.values.type) {
            case 'ach':
              if (!valRouting.institution.isACHSupported) {
                form.setFieldError(
                  'routingNumber',
                  'Invalid routing number for ACH payments',
                );
                return null;
              }
              break;
            case 'wire':
              if (!valRouting.institution.isWireSupported) {
                form.setFieldError(
                  'routingNumber',
                  'Invalid routing number for domestic wire payments',
                );
                return null;
              }
              break;
          }
        } catch (e) {
          form.setFieldError(
            'routingNumber',
            'Routing number could not be validated',
          );
          return null;
        }

        return form.values;
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        return submit();
      },
    }));

    return (
      <Box mt="1rem" mb="1rem">
        <div className={classes.nicknameLabel}>
          Nickname <span>(optional)</span>
        </div>
        <TextInput
          placeholder="Enter nickname (optional)"
          {...form.getInputProps('nickName')}
          data-testid="nickName"
          c="neutral.8"
        />
        <TextInput
          className={classes.inputContainer}
          label="Routing number"
          placeholder="Enter routing number"
          {...form.getInputProps('routingNumber')}
          maxLength={9}
          onChange={(e) => setFormFieldNumber(e.target.value, 'routingNumber')}
          data-testid="routingNumber"
          c="neutral.8"
        />
        <TextInput
          className={classes.inputContainer}
          label="Account number"
          placeholder="Enter account number"
          {...form.getInputProps('accountNumber')}
          onChange={(e) => setFormFieldNumber(e.target.value, 'accountNumber')}
          data-testid="accountNumber"
        />

        <>
          {isNewRecipient && (
            <Radio.Group
              mt={20}
              {...form.getInputProps('recipientType')}
              onChange={(value: IndividualOrCompany) => {
                form.setFieldValue('recipientType', value);
                if (value === 'company') {
                  form.setFieldValue('lastName', '');
                }
              }}
              label="Recipient type"
            >
              <Group mt={12}>
                <Radio value="individual" label="Person" />
                <Radio value="company" label="Business" />
              </Group>
            </Radio.Group>
          )}

          {isNewRecipient && form.values.recipientType === 'individual' && (
            <>
              <Flex justify="space-between">
                <TextInput
                  mt={10}
                  label="First name"
                  data-testid="first-name"
                  placeholder="First name"
                  {...form.getInputProps('firstName')}
                />

                <TextInput
                  mt={10}
                  label="Last name"
                  data-testid="last-name"
                  placeholder="Last name"
                  {...form.getInputProps('lastName')}
                />
              </Flex>
              <FlexPatternFormat
                mt={10}
                label="Date of birth (optional)"
                data-testid="dob"
                placeholder="Date of birth"
                description="Format: YYYY-MM-DD"
                format="####-##-##"
                mask="_"
                allowEmptyFormatting
                {...form.getInputProps('dob')}
              />
            </>
          )}
        </>

        {method === 'ach' ? (
          <Select
            placeholder="Account type"
            label="Account type"
            data={['Checking', 'Savings']}
            className={classes.inputContainer}
            {...form.getInputProps('accountType')}
          />
        ) : (
          <>
            <div className={classes.sectionHeader}>
              Recipient&apos;s Legal Address
            </div>
            <GooglePlacesSuggest
              label="Recipient legal address"
              {...form.getInputProps('address')}
              onItemSubmit={selectAddress}
              placeholder="Address"
              className={classes.inputContainer}
              id="input-search-address"
            />
            <TextInput
              mt={10}
              label="Apartment, suite, floor (optional)"
              data-testid="address2"
              placeholder="Apt, suite, etc. (Optional)"
              {...form.getInputProps('addressLine2')}
            />
            <TextInput
              mt={10}
              label="City"
              data-testid="city"
              placeholder="City"
              {...form.getInputProps('city')}
            />
            <Select
              mt={10}
              label="State"
              placeholder="State"
              data-testid="state"
              data={US_STATES}
              searchable
              maxDropdownHeight={400}
              nothingFound="No data"
              filter={(value, item) => {
                const lowerCaseValue = value.toLowerCase();
                return (
                  item.label?.toLowerCase().includes(lowerCaseValue) ||
                  item.value.toLowerCase().includes(lowerCaseValue)
                );
              }}
              {...form.getInputProps('state')}
            />
            <TextInput
              mt={10}
              label="ZIP Code"
              data-testid="zip"
              placeholder="Zip Code"
              pattern={'[0-9]*'}
              {...form.getInputProps('postalCode')}
              onChange={(e) => setZip(e.target.value)}
            />
            <TextInput
              mt={10}
              label="Country"
              data-testid="country"
              placeholder="US"
              pattern={'[0-9]*'}
              {...form.getInputProps('country')}
              onChange={(e) => setCountry(e.target.value)}
            />
          </>
        )}
      </Box>
    );
  },
);

const useStyles = createStyles((theme) => ({
  inputContainer: { marginTop: theme.spacing.md },
  sectionHeader: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 500,
    fontSize: '24px',
    color: '#000000',
    marginTop: '1rem',
  },
  nicknameLabel: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    marginBottom: '0.5rem',
    span: {
      color: theme.fn.themeColor('neutral', 6),
    },
  },
  selectLabel: {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '19px',
    color: '#5F5F5F',
  },
}));

PaymentRecipientForm.displayName = 'PaymentRecipientForm';

export default PaymentRecipientForm;
