import { atom } from 'recoil';
import {
  ActiveFiltersReturnType,
  createUseFiltersHook,
  FilterFnMap,
} from '@common/filter/filters';
import { AccountTableData } from './banking-accounts';

const BankingAccountsFilterState = atom<FilterFnMap<AccountTableData>>({
  key: 'banking_accounts_filter_state',
  default: {},
});

export function useBankingAccountsFilters(): ActiveFiltersReturnType<AccountTableData> {
  return createUseFiltersHook<AccountTableData>(BankingAccountsFilterState);
}
