import { AuthenticationTokenModel } from '@services/platform/models/authorize.models';
import { AuthenticationFactor } from '@flexbase-eng/types/dist/identity';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from '../../states/application/product-onboarding';
import { intersection } from 'underscore';
import { ProductApplicationRoutes } from '../onboarding-v2/onboarding.constants';
import { notifications } from '@mantine/notifications';
import { useAuthToken } from '../../states/auth/auth-token';
import { useSetRecoilState } from 'recoil';
import { useProvePrefillFeatureFlag } from '@utilities/feature-flags';
import { useRouteSectionContext } from '@common/routes/route-context';
import { useNavigate } from 'react-router-dom';
// import { getEnvironment } from '@utilities/url/window-helpers';
// import { FullStory } from '@fullstory/browser';
import { platformAuthClient } from '@services/platform/platform-auth-client';
import { integrationModalState } from 'areas/company-settings/integrations/components/integration-modal/integration-modal-state';

//const hostEnv = getEnvironment(window.location.host);

type LoginParams = {
  factors: AuthenticationFactor[];
  onError: () => void;
  userData: { remember: boolean; email: string };
};

export const useHandleLoginSuccess = () => {
  const { signIn, clearToken } = useAuthToken();
  const setUserAndCompanyData = useSetRecoilState(ApplicationState);
  const { treatmentOn: allowPrefill } = useProvePrefillFeatureFlag();
  const { setShowRoutesFor, setSectionAndNavigate } = useRouteSectionContext();
  const navigate = useNavigate();
  const setIntegrationModalState = useSetRecoilState(integrationModalState);

  const handleLoginSuccess = async (
    token: AuthenticationTokenModel,
    params?: Partial<LoginParams>,
  ) => {
    signIn(token, params?.userData);
    try {
      const status = await getProductOnboardingStatus();
      const getFactorsResponse = await platformAuthClient.getFactors();

      setUserAndCompanyData(status);

      /*
      if (hostEnv === 'production') {
        FullStory('setIdentityAsync', {
          uid: status?.user.id,
          properties: {
            companyId: status.company.id,
            email: status.user.email,
          },
        });
      }
        */

      if (!status.completedOnboarding) {
        // Only send to prefill flow if it is enabled, they have a Platform account, and they need info we can potentially prefill
        const prefillableReqs = [
          'user.taxId',
          'user.birthDate',
          'user.firstName',
          'user.lastName',
        ];

        const navigateToPrefill =
          allowPrefill &&
          getFactorsResponse.body?.knowledge.some(
            (f) => f.method === 'password',
          ) &&
          intersection(prefillableReqs, status.requiredProperties).length > 0;

        if (navigateToPrefill) {
          setSectionAndNavigate('application', '/prefill');
        } else {
          // Try to figure out which app to drop them in.
          const creditIncomplete =
            status.productStatus.credit.status === 'incomplete';
          const bankingIncomplete =
            status.productStatus.banking.status === 'incomplete';
          if (creditIncomplete && bankingIncomplete) {
            setSectionAndNavigate(
              'application',
              ProductApplicationRoutes.COMBINED_CREDIT_BANKING,
            );
          } else if (bankingIncomplete) {
            setSectionAndNavigate(
              'application',
              ProductApplicationRoutes.BANKING_ONLY,
            );
          } else if (creditIncomplete) {
            setSectionAndNavigate(
              'application',
              ProductApplicationRoutes.CREDIT_ONLY,
            );
          } else {
            setShowRoutesFor('application'); // Shouldn't get hit
          }
        }
      } else if (
        getFactorsResponse.body?.knowledge.filter(
          (f) => f.method === 'password',
        ).length === 0
      ) {
        navigate('/change-password');
      } else if (
        getFactorsResponse.body?.possession.filter((f) => f.method === 'sms')
          .length === 0
      ) {
        setSectionAndNavigate('main', '/security/factors');
      } else {
        setShowRoutesFor('main');
      }
    } catch (e) {
      notifications.show({
        title: 'Unable to load user data',
        message: 'An error occurred when loading user data. Please login again',
        autoClose: 10000,
      });
      clearToken();
      if (params?.onError) {
        params.onError();
      }
    }
  };

  const handleLoginSuccessOauth = async (
    token: AuthenticationTokenModel,
    params?: Partial<LoginParams>,
  ) => {
    signIn(token, params?.userData);
    try {
      const status = await getProductOnboardingStatus();

      setUserAndCompanyData(status);
      setIntegrationModalState((prev) => ({
        ...prev,
        loginDataLoaded: true,
      }));
    } catch (e) {
      notifications.show({
        title: 'Unable to load user data',
        message: 'An error occurred when loading user data. Please login again',
        autoClose: 10000,
      });
      clearToken();
      if (params?.onError) {
        params.onError();
      }
    }
  };

  return { handleLoginSuccess, handleLoginSuccessOauth };
};
