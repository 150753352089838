import { Center, createStyles, Flex, Grid, Loader, Text } from '@mantine/core';
import BillpayCard from './billpay-card';
import { useGetBillingBalance } from '@queries/use-billing-internal';
import { IoAlertCircleOutline } from 'react-icons/io5';

const BillpaySummary = () => {
  const { classes, theme } = useStyles();
  const { data, isLoading, isError } = useGetBillingBalance();
  const {
    outstanding = { count: 0, balance: 0 },
    overdue = { count: 0, balance: 0 },
    upcoming = { count: 0, balance: 0 },
  } = data?.balances || {};

  const billpayDataArray = [
    {
      title: 'Total outstanding bills',
      balance: outstanding.balance,
      billsCount: outstanding.count,
    },
    {
      title: 'Overdue',
      balance: overdue.balance,
      billsCount: overdue.count,
    },
    {
      title: 'Due in next 7 days',
      balance: upcoming.balance,
      billsCount: upcoming.count,
    },
  ];

  if (isLoading) {
    return (
      <Center mb="1rem">
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <Grid
        grow
        gutter={0}
        mb={isError ? 0 : '1rem'}
        style={{
          borderRadius: 8,
          overflow: 'hidden',
          borderBottomLeftRadius: isError ? 0 : 8,
          borderBottomRightRadius: isError ? 0 : 8,
        }}
      >
        {billpayDataArray.map((billpayData) => (
          <Grid.Col span={4} key={billpayData.title}>
            <BillpayCard
              title={billpayData.title}
              balance={billpayData.balance}
              error={isError}
              billsCount={billpayData.billsCount}
            />
          </Grid.Col>
        ))}
      </Grid>
      {isError && (
        <Flex px="1rem" mb="1rem" className={classes.containerError}>
          <Flex align="center">
            <IoAlertCircleOutline color={theme.colors.red[6]} size={22} />{' '}
            <Text mt="0.1rem" ml="0.2rem" weight={500}>
              {' '}
              Error loading data
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default BillpaySummary;

const useStyles = createStyles((theme) => ({
  containerError: {
    backgroundColor: theme.colors.red[0],
    border: `1px solid ${theme.colors.red[5]}`,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    height: '2rem',
  },
}));
