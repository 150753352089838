import { useRecoilState, useRecoilValue } from 'recoil';
import { Center, LoadingOverlay, Stack } from '@mantine/core';
import { useEffect, useState } from 'react';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import CardPaymentAmount from './card-payment-amount';
import CardPaymentReview from './card-payment-review';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { Analytics } from 'services/analytics/analytics';
import { ApplicationState } from 'states/application/product-onboarding';
import {
  depositAccountsQuery,
  paymentAccountQuery,
} from 'areas/payments/components/send-payment/payment.states';
import type {
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';
import { useExternalAccounts } from '@utilities/custom-hooks/use-external-accounts';
import { AddAccount } from './add-account';
import { useGetChargeCardAccounts } from '@queries/use-charge-card-accounts';
import { AccountLOC } from './line-of-credit-select';
import { useGetMinimumDue } from '@queries/use-company';

type Props = {
  closeModal: () => void;
  onSuccess: (
    paymentId: string,
    paymentAmount: number,
    paymentAccount: DepositAccount | PlaidAccount,
    paymentStatus: string,
  ) => void;
  onError: () => void;
};

const ReviewAndPay = ({ closeModal, onSuccess, onError }: Props) => {
  const { company } = useRecoilValue(ApplicationState);
  const [reviewing, setReviewing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [lineOfCreditAccount, setLineOfCreditAccount] = useState<AccountLOC>();

  const {
    data: balance,
    isLoading: isCompanyBalanceLoading,
    isError: isCompanyBalanceError,
  } = useGetMinimumDue(company.id);
  const frozen = balance?.frozen ?? false;
  const maxPayment = balance?.maximumAllowedPayment ?? 0;
  // check to not present a negative value to the user
  const currentBalance =
    balance && balance.currentBalance > 0 ? balance.currentBalance : 0;
  const minimumDue = balance && balance.minimumDue > 0 ? balance.minimumDue : 0;
  const currentPaymentAmount = frozen ? maxPayment : currentBalance;
  const delinquentAmount = balance?.delinquentAmount ?? 0;
  const interestDue = balance?.interestDue ?? 0;
  const minimumAllowedPayment = balance?.minimumAllowedPayment ?? 0;
  const minPayment = frozen ? interestDue : minimumAllowedPayment;
  const comeCurrentPayment = frozen ? balance?.comeCurrentPayment ?? 0 : 0;
  const availableLimit = balance?.availableLimit ?? 0;

  const [paymentAmount, setPaymentAmount] = useState(currentPaymentAmount);

  const depositAccounts = useRecoilValue(depositAccountsQuery);
  const { data: plaidAccounts, refetch } = useExternalAccounts();
  const [paymentAccount, setPaymentAccount] =
    useRecoilState(paymentAccountQuery);

  const {
    data: chargeAccountsData,
    isLoading: isChargeAccountsLoading,
    isError: isChargeAccountsError,
  } = useGetChargeCardAccounts();
  const chargeAccounts =
    chargeAccountsData?.accounts.filter((acc) => acc.status !== 'Closed') ?? [];

  const onConfirmClick = async () => {
    setLoading(true);

    try {
      // SAFETY: paymentAccount *must* exist by this point
      // (see the assertion in the "stack" variable below)
      const paymentAccountId = paymentAccount!.id;

      let paymentResult;
      if (paymentAccount?.plaidOrDeposit === 'plaid') {
        paymentResult = await flexbaseOnboardingClient.makePaymentPlaid({
          amount: paymentAmount,
          plaidTokenId: paymentAccountId,
        });
      } else {
        paymentResult = await flexbaseOnboardingClient.makePaymentUnit({
          amount: paymentAmount,
          depositAccountId: paymentAccountId,
          chargeCardAccountId: lineOfCreditAccount?.id,
          lineOfCredit: lineOfCreditAccount?.lineOfCredit,
        });
      }

      const failedStatus = 'failed' || 'Rejected';

      if (
        paymentResult.success &&
        paymentResult.cardPayment.status !== failedStatus
      ) {
        Analytics.track('Credit Payment Submitted', { paymentAmount });
        onSuccess(
          paymentResult.cardPayment.id,
          paymentAmount,
          paymentAccount!,
          paymentResult.cardPayment.status,
        );
      } else {
        onError();
      }
    } catch (e) {
      onError();
    } finally {
      setLoading(false);
    }
  };

  const isLoading = isCompanyBalanceLoading || isChargeAccountsLoading;
  const isError = isCompanyBalanceError || isChargeAccountsError;

  useEffect(() => {
    if (isError) {
      onError();
    }
  }, [isError]);

  useEffect(() => {
    const unlinked = plaidAccounts.some(
      (account) => account.active && account.unlinked,
    );

    if (!loading && unlinked && !token) {
      flexbaseClient
        .getPlaidLinkToken()
        .then((plaidLinkToken) => setToken(plaidLinkToken));
    }
  }, [loading, plaidAccounts]);

  const paymentAccounts = [
    ...depositAccounts
      .filter((a) => a.status === 'Open')
      .sort((a, b) => b.balance - a.balance),
    ...plaidAccounts.reduce((accounts, account) => {
      if (
        !account.unlinked &&
        !accounts.some(({ last4 }) => account.last4 === last4)
      ) {
        accounts.push(account);
      }
      return accounts;
    }, [] as PlaidAccount[]),
  ];

  const handleAddAccountSuccess = (accounts: PlaidAccount[]) => {
    refetch();
    setPaymentAccount(accounts[0]); // Famous last words but this should always have at least one entry, otherwise the fn is never called
  };

  const stack = paymentAccount ? (
    reviewing ? (
      <CardPaymentReview
        frozen={frozen}
        paymentAccount={paymentAccount}
        paymentAmount={formatCurrency(paymentAmount)}
        onConfirmClick={onConfirmClick}
        onGoBackClick={() => setReviewing(false)}
        loadingRequest={loading}
      />
    ) : (
      <CardPaymentAmount
        delinquentAmount={delinquentAmount}
        frozen={frozen}
        interestDue={interestDue}
        paymentAmount={paymentAmount}
        currentBalance={currentBalance}
        minimumDue={minimumDue}
        maxPaymentAmount={maxPayment}
        minPaymentAmount={minPayment}
        comeCurrentAmount={comeCurrentPayment}
        closeModal={closeModal}
        onReviewClick={() => setReviewing(true)}
        paymentAccounts={paymentAccounts}
        currentAccount={paymentAccount}
        onPaymentAmountChange={setPaymentAmount}
        onPaymentAccountChange={setPaymentAccount}
        chargeAccounts={chargeAccounts}
        availableLimit={availableLimit}
        lineOfCreditAccount={lineOfCreditAccount}
        onLineCreditAccountChange={setLineOfCreditAccount}
      />
    )
  ) : (
    <AddAccount
      onPlaidAccountLinked={handleAddAccountSuccess}
      setLoading={setLoading}
    />
  );

  return (
    <>
      <Center>
        <LoadingOverlay visible={isLoading} />
      </Center>
      {!isLoading && <Stack>{stack}</Stack>}
    </>
  );
};

export default ReviewAndPay;
