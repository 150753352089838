import { ValidateRoutingNumber } from '@flexbase-eng/web-components';
import { Loader, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useRoutingNumberSupport } from '@queries/use-routing-number-support';
import { InstitutionResponse } from '@services/flexbase/banking.model';
import { PayMethod } from 'areas/payments/components/send-payment/payment.states';

type RoutingNumber = { routingNumber: string };
type RoutingNumberForm<T extends RoutingNumber> = UseFormReturnType<T>;

type Props<T extends RoutingNumber> = {
  paymentMethod: PayMethod;
  form: RoutingNumberForm<T>;
};

const getRoutingNumberErrorByType = (
  routingData: InstitutionResponse | undefined,
  type: PayMethod,
): string | null => {
  switch (type) {
    case 'ach':
      if (routingData && !routingData.institution.isACHSupported) {
        return 'The bank associated with this routing number does not accept ACH payments';
      }
      break;
    case 'wire':
      if (routingData && !routingData.institution.isWireSupported) {
        return 'The bank associated with this routing number does not accept wire payments';
      }
      break;
  }

  return null;
};

const RoutingNumberInput = <T extends RoutingNumber>({
  paymentMethod,
  form,
}: Props<T>) => {
  const routingNumber = form.values.routingNumber;
  const {
    data: validationResult,
    isLoading,
    isError,
  } = useRoutingNumberSupport(
    routingNumber,
    ValidateRoutingNumber(routingNumber) && routingNumber.length === 9,
  );

  const error = isError
    ? `Routing number is invalid for ${paymentMethod} payments`
    : getRoutingNumberErrorByType(validationResult, paymentMethod) ??
      form.errors.routingNumber;

  const handleError = () => {
    form.setFieldError('routingNumber', error);
  };

  return (
    <TextInput
      sx={(theme) => ({
        marginTop: theme.spacing.md,
        width: '48%',
      })}
      label="Routing number"
      placeholder="Enter routing number"
      maxLength={9}
      data-testid="routingNumber"
      rightSection={isLoading ? <Loader size="xs" /> : null}
      {...form.getInputProps('routingNumber')}
      error={error}
      onError={handleError}
    />
  );
};

export default RoutingNumberInput;
