import { createStyles } from '@mantine/core';

export const useWizardStyles = createStyles((theme) => ({
  body: {
    alignItems: 'stretch',
    flexGrow: 1,
    flexWrap: 'nowrap',
    minHeight: '100%',
    justifyContent: 'center',
    overflow: 'visible',
    padding: '3rem',

    [theme.fn.smallerThan('sm')]: {
      padding: '1rem',
    },
  },
  contentPanel: {
    flexBasis: '50%',
    flexGrow: 1,
    flexShrink: 1,

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
    },
  },
  previewPanel: {
    backgroundColor: '#fff',
    borderRadius: theme.defaultRadius,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.fn.themeColor('neutral', 3),
    flexBasis: '50%',
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: 767,
    width: 'auto',
  },
  scrollContainer: {
    overflow: 'auto',
    position: 'relative',
  },
  scrollContent: {
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  previewStack: {
    height: '100%',
    spacing: 0,
  },
  previewEditIcon: {
    backgroundColor: theme.fn.themeColor('neutral', 2),
    opacity: 0,
    padding: 4,
    position: 'absolute',
    top: 16,
    transition: 'opacity 250ms',
    right: 16,
  },
  previewSection: {
    backgroundColor: 'transparent',
    position: 'relative',
    transition: 'background-color 250ms',

    '.edit': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
      opacity: 0,
      padding: 4,
      position: 'absolute',
      top: 16,
      transition: 'opacity 250ms',
      right: 16,
    },
  },
  previewSectionVisited: {
    ...theme.fn.hover({
      backgroundColor: theme.fn.themeColor('neutral', 2),
      borderRadius: theme.defaultRadius,

      '.edit': {
        opacity: 1,
        cursor: 'pointer',
      },
    }),
  },
  previewTitle: {
    flexGrow: 1,
  },
}));
